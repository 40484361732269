<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.keyword" placeholder="请输入分类名称" style="width: 220px;" class="filter-item" clearable/>
      <el-select class="filter-item" filterable v-model="listQuery.canteen_id" placeholder="请选择食堂" style="width: 140px" clearable>
        <el-option v-for="item in canteens" :key="item.id" :label="item.canteen_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" filterable v-model="listQuery.shop_id" placeholder="请选择店铺" style="width: 140px" clearable>
        <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
        </el-option>
      </el-select>
      <el-select class="filter-item" v-model="listQuery.is_show" placeholder="请选择状态" style="width: 140px" clearable>
        <el-option label="已上架" :value="1"></el-option>
        <el-option label="已下架" :value="0"></el-option>
      </el-select>
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
      <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="handleAdd">添加</el-button>
      <!-- <el-button class="filter-item" type="primary" style="float: right" @click="handleCopy">复制商品</el-button> -->
    </div>
    <div class="filter-container">
      <el-button-group>
        <el-button type="primary" size="small" @click="handleIsShow(1)" :disabled="ids.length <= 0">批量上架</el-button>
        <el-button type="warning" size="small" @click="handleIsShow(0)" :disabled="ids.length <= 0">批量下架</el-button>
        <el-button type="danger" size="small" @click="handleDel" :disabled="ids.length <= 0">批量删除</el-button>
      </el-button-group>
    </div>
    <el-table v-loading="listLoading" :data="list" @selection-change="handleSelectionChange" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column type="selection" align="center" width="40"></el-table-column>
      <el-table-column align="center" label="ID" width="60">
        <template slot-scope="scope">
          <!-- {{ scope.$index }} -->
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="学校" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.school.school_name }}
        </template>
      </el-table-column>
      <el-table-column label="店铺" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.shop.shop_name }}
        </template>
      </el-table-column>
      <el-table-column label="分类名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.category_name }}
        </template>
      </el-table-column>
      <el-table-column label="权重" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>
      <el-table-column label="上下架" min-width="80" align="center">
        <template slot-scope="scope">
          <el-tag type="primary" v-if="scope.row.is_show == 1">已上架</el-tag>
          <el-tag type="danger" v-else>已下架</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="220" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button type="success" size="mini" @click="handleToGoods(scope.row)">商品</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <el-form ref="form" :model="form" label-position="left" label-width="90px">
        <el-form-item label="店铺">
          <el-select :disabled="form.id ? true : false" class="filter-item" filterable v-model="form.shop_id" placeholder="请选择店铺" style="width: 140px" clearable>
            <el-option v-for="item in shops" :key="item.id" :label="item.shop_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称">
          <el-input v-model="form.category_name" placeholder="请输入分类名称" />
        </el-form-item>
        <el-form-item label="权重">
          <el-input v-model="form.weight" type="number" min="0" placeholder="权重值越大，排序越靠前" />
        </el-form-item>
        <el-form-item label="是否必选">
          <el-switch v-model="form.is_require" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";
import { mapGetters } from "vuex";
import { guid } from "@/utils/index";

export default {
  data() {
    return {
      canteens: [],
      shops: [],
      categories: [],
      list: null,
      total: null,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        school_id: "",
        canteen_id: "",
        shop_id: "",
        category_id: "",
        is_show: ""
      },
      dialogVisible: false,
      btnLoading: false,
      form: {
        id: "",
        school_id: "",
        canteen_id: "",
        shop_id: "",
        category_name: "",
        weight: 0,
        is_require: 0,
      },
      ids: [],
      qiniu: {
        actionPath: "",
        baseUrl: "",
        postData: {}
      }
    };
  },
  created() {
    if (this.$route.query.shop_id) {
      this.listQuery.shop_id = parseInt(this.$route.query.shop_id);
    }
    this.getList();
    this.getCanteenList();
    this.getShopList();
  },
  computed: {
    ...mapGetters(["schools", "school_id"])
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.getList();
      this.getShopList();
    },
    "listQuery.canteen_id": {
      handler(newValue, oldValue) {
        this.getShopList();
      },
      deep: true
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: "",
          school_id: "",
          shop_id: "",
          category_name: "",
          weight: 0,
          is_require: 0,
        };
      }
    }
  },
  methods: {
    getList() {
      this.listQuery.school_id = this.school_id;
      this.listLoading = true;
      request({
        url: "/api/backend/category/list",
        method: "get",
        params: this.listQuery
      }).then(response => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getCanteenList() {
      request({
        url: "/api/backend/canteen/list",
        method: "get",
        params: {
          page: 0,
          limit: 1000000,
          school_id: this.school_id,
        }
      }).then(response => {
        this.canteens = response.data.data;
      });
    },
    getShopList() {
      request({
        url: "/api/backend/shop/list",
        method: "get",
        params: {
          page: 1,
          limit: 1000000,
          school_id: this.school_id,
          canteen_id: this.listQuery.canteen_id,
        }
      }).then(response => {
        this.shops = response.data.data;
      });
    },
    handleToGoods(item){
      this.$router.push(`/shop/goods?shop_id=${item.shop_id}&category_id=${item.id}`)
    },
    uploadSuccess(res) {
      this.form.logo = this.qiniu.baseUrl + "/" + res.key;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleAdd() {
      this.dialogVisible = true;
    },
    handleEdit(item) {
      this.form = {
        id: item.id,
        school_id: item.school_id,
        shop_id: item.shop_id,
        category_name: item.category_name,
        weight: item.weight,
        is_require: item.is_require,
      };
      this.dialogVisible = true;
    },
    handleIsShow(is_show) {
      let text = is_show == 1 ? "上架" : "下架";
      this.$confirm("确定要" + text + "选中分类吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/category/isShow",
          method: "post",
          data: {
            ids: this.ids,
            is_show: is_show
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleDel() {
      this.$confirm("确定要删除选中分类吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        request({
          url: "/api/backend/category/del",
          method: "post",
          data: {
            ids: this.ids
          }
        }).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        });
      });
    },
    handleSelectionChange(val) {
      let ids = [];
      val.forEach(element => {
        ids.push(element.id);
      });
      this.ids = ids;
    },
    saveData() {
      this.form.school_id = this.school_id;
      if (!this.form.school_id) {
        this.$message({
          type: "warning",
          message: "请先选择学校"
        });
        return;
      }
      if (!this.form.id && !this.form.shop_id) {
        this.$message({
          type: "warning",
          message: "请选择指定店铺"
        });
        return;
      }
      if (!this.form.category_name) {
        this.$message({
          type: "warning",
          message: "请输入分类名称"
        });
        return;
      }
      if (this.form.weight < 0) {
        this.$message({
          type: "warning",
          message: "请输入正确的权重值"
        });
        return;
      }
      this.btnLoading = true;
      request({
        url: "/api/backend/category/save",
        method: "post",
        data: this.form
      })
        .then(() => {
          this.dialogVisible = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功!"
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleDownload() {}
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
</style>
